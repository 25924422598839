
import RcDomainObject from "../../common/RcDomainObject.js";
import Company from "../company/Company.js"
import User from "../user/User.js"
import RoleMap from "../role/RoleMap.js"
import DateUtils from "@/utils/DateUtils.js";
import EmployeeMap from "./EmployeeMap.js"

export default class Employee extends RcDomainObject {

  static MODEL_NAME = "Employee";
  
  static FIELDS = {
    USER : User.MODEL_NAME,
    COMPANY : Company.MODEL_NAME,
    ROLES: RoleMap.MODEL_NAME,
    EMPLOYMENT_ID: 'employmentId',
    STATE: 'state',
    CREATED_DATE: 'createdDate',
    COMPLIANCE_TYPE: 'complianceType',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
  }
   
  static Values = {
      ComplianceType: {
        EXCLUDED: 'excluded',
        INCLUDED: 'included',
      }
  }
  constructor(domain, data) {
    super(domain, data, Employee.MODEL_NAME);
  }

  fields() {
    return Company.FIELDS;
  }

  find() {
    return this.domain.employees().findById(this.id());
  }
  
  user() {
    return new User(this.domain, this.data[Employee.FIELDS.USER]);
  }
  
  withUser(user) {
    if (user.data) {
      this.put(Employee.FIELDS.USER, user.data);
    } else {
      this.put(Employee.FIELDS.USER, user);
    }
    return this;
  }
  
  roles() {
    return new RoleMap(this.domain, this.data[Employee.FIELDS.ROLES]);
  }
  
  withRole(role) {
    let roleMap = new RoleMap(this.domain);
    roleMap.add(role);
    return this.withRoles(roleMap);
  }

  withRoles(roleMap) {
    this.put(Employee.FIELDS.ROLES, roleMap.data ? roleMap.data : roleMap);
    return this;
  }

  state() {
    return this.getString(Employee.FIELDS.STATE);
  }
  
  isStateInactive() {
    var is = this.state() === "inactive";
    return is;
  }
  
  isStateNotInvited() {
    var state = this.state();
    return state === 'notinvited';
  }

  isStateActive() {
    var is = this.state() === "active";
    return is;
  }
  
  isStateActiveOrInvited() {
    var is = this.isStateActive() || this.isStateInvited();
    return is;
  }
  
  isStateInvited() {
    var is = this.state() === "invited";
    return is;
  }
  
  employmentId() {
    return this.getString(Employee.FIELDS.EMPLOYMENT_ID);
  }
  
  withEmploymentId(id) {
    this.put(Employee.FIELDS.EMPLOYMENT_ID, id);
    return this;
  }

  createdDate() {
    var value = this.get(Employee.FIELDS.CREATED_DATE);
    return value;
  }

  startDate() {
    var value = this.getString(Employee.FIELDS.START_DATE);
    return value;
  }

  endDate() {
    var value = this.getString(Employee.FIELDS.END_DATE);
    return value;
  }

  createdDateDisplay() {
    var value = this.createdDate();
    return DateUtils.toDateTime(value);
  }
  
  company() {
    var companyData = this.data[Employee.FIELDS.COMPANY];
    return new Company(this.domain, companyData);
  }
  
  withCompany(company) {
    this.put(Employee.FIELDS.COMPANY, company);
    return this;
  }
  
  hasBeenExcluded() {
    var type = this.getString(Employee.FIELDS.COMPLIANCE_TYPE);
    return type === Employee.Values.ComplianceType.EXCLUDED;
  }
 
  hasBeenIncluded() {
    var type = this.getString(Employee.FIELDS.COMPLIANCE_TYPE);
    return type === Employee.Values.ComplianceType.INCLUDED;
  }
 
  complianceType() {
    var type = this.getString(Employee.FIELDS.COMPLIANCE_TYPE);
    return type;
  }
  
  withComplianceTypeIncluded() {
    this.put(Employee.FIELDS.COMPLIANCE_TYPE, Employee.Values.ComplianceType.INCLUDED)
    return this;
  }
  
  withComplianceTypeExcluded() {
    this.put(Employee.FIELDS.COMPLIANCE_TYPE, Employee.Values.ComplianceType.EXCLUDED)
    return this;
  }
  
  map() {
    var map = new EmployeeMap(this.domain);
    map.add(this);
    return map;
  }
}