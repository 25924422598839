
import RcDomainObject from "../../common/RcDomainObject";

import StringUtils from '@/utils/StringUtils.js'

export default class Address extends RcDomainObject {
  
  static MODEL_NAME = "Address";
      
  static FIELDS = {
    NAME : 'name',
    TYPE: 'type',
    DESCRIPTION: 'description',
    STREET: 'street',
    UNIT: 'unit',
    CITY : 'city',
    JURISDICTION: 'jurisdiction',
    COUNTRY: 'country',
    POSTAL: 'postal',
    ADDRESS: 'address',
  }
  
  constructor(domain, data) {
    super(domain, data, Address.MODEL_NAME);
  }

  fields() {
    return Address.FIELDS;
  }
  
  find() {
    return this.domain.addresses().findById(this.id());
  }
  
  name() {
    return this.getString(Address.FIELDS.NAME);
  }
  
  copy() {
    var data = this._copy();
    return new Address(this.domain, data);
  }
  
  country() {
    return this.getString(Address.FIELDS.COUNTRY);
  }
  
  withCountry(value) {
    this.put(Address.FIELDS.COUNTRY, value);
    return this;
  }
  
  province() {
    return this.getString(Address.FIELDS.JURISDICTION);
  }
  
  withProvince(value) {
    this.put(Address.FIELDS.JURISDICTION, value);
    return this;
  }

  unit() {
    return this.getString(Address.FIELDS.UNIT);
  }
  
  postal() {
    return this.getString(Address.FIELDS.POSTAL);
  }
  
  withPostal(value) {
    this.put(Address.FIELDS.POSTAL, value);
    return this;
  }
  
  city() {
    return this.getString(Address.FIELDS.CITY);
  }
  
  withCity(value) {
    this.put(Address.FIELDS.CITY, value);
    return this;
  }
  
  street() {
    return this.getString(Address.FIELDS.STREET);
  }
  
  withStreet(value) {
    this.put(Address.FIELDS.STREET, value);
    return this;
  }
  
  location() {
    return StringUtils.format("{0} {1} {2} {3} {4}", 
        [this.street(), this.unit(), this.city(), 
        this.province(), this.postal()]);
  }
  
  isCanada() {
    var c = this.country();
    if (c) {
      if (c.toLowerCase() === "canada") {
        return true;
      }
    }
    return false;
  }
}