
import { uuid } from 'vue-uuid'

import ConstUtils from '@/utils/ConstUtils.js'
import ContentUtils from '@/utils/ContentUtils.js'
import StringUtils from '@/utils/StringUtils.js'

export default class RcObject {

  constructor(data) {
    if (data && data.data) {
      this.data = data.data;
    } else {
      this.data = data;
    }
    if (!this.data) {
      this.data = {
          '@rid': uuid.v1(),
      };
    }
  }

  getData() {
    return this.data;
  }
  
  getString(key) {
    return StringUtils.toString(this.get(key));
  }

  getInt(key) {
    var value = this.get(key);
    if (value) {
      return parseInt(value);
    }
    return 0;
  }
  
  getBoolean(key) {
    var value = this.get(key);
    if (value) {
      var stringValue = StringUtils.toString(value).toLowerCase();
      if (stringValue === "0") {
        return false;
      }
      if (stringValue === "1") {
        return true;
      }
      if (stringValue === "false") {
        return false;
      }
      if (stringValue === "true") {
        return true;
      }
      return value;
    }
    return false;
  }
  
  getDouble(key) {
    var value = this.get(key);
    if (value) {
      return value;
    }
    return 0.0;
  }
  
  contains(key) {
    return this.has(key);
  }
  
  has(key) {
    var keyList = Object.keys(this.data);
    return keyList.includes(key);
  }
  
  get(key) {
    if (this.data == null) {
      return null;
    }
    var value = this.data[key];
    return value;
  }
  
  put(key, value) {
    if (value && value.data) {
      this.data[key] = value.data;
    } else {
      this.data[key] = value;
    }
    return this;
  }
  
  add(value) {
    if (value.data) {
      this.put(value.id(), value.data);
    } else {
      this.put(value['@rid'], value);
    }
    return this;
  }
  
  remove(value) {
    if (value.data) {
      delete this.data[value.id()];
    } else {
      var id = value['@rid'];
      if (id) {
        delete this.data[id];
      } else {
        delete this.data[value];
      }
    }
  }
  
  toId(obj) {
    var value = null;
    if (obj) {
      if (obj.data) {
        value = obj.data[ConstUtils.FIELDS.ID];
      } else {
        value = obj[ConstUtils.FIELDS.ID];
      }
    }
    return value;
  }

  isNew() {
    return !this.id().startsWith("#");
  }
  
  isNotNew() {
    return this.id().startsWith("#");
  }
  
  keys() {
    return Object.keys(this.data);
  }
  
  values() {
    return Object.values(this.data);
  }
  
  size() {
    return this.length();
  }
  
  length() {
    return this.keys().length;
  }
  
  isEmpty() {
    return this.length() == 0;
  }
  
  isNotEmpty() {
    return this.length() > 0;
  }

  id() {
    return StringUtils.toString(this.data[ConstUtils.FIELDS.ID]);
  }
  
  idEncoded() {
    var encoded = ContentUtils.baseEncode(this.id());
    return encoded;
  }

  withId(newId) {
    this.data[ConstUtils.FIELDS.ID] = newId;
    return this;
  }
  
  idEquals(object) {
    if (object) {
      if (object.id) {
        return this.id() === object.id()
      } else {
        return this.id() === object
      }
    }
    return false
  }
  

  done() {
    
  }
  
  _copy() {
    var stringData = JSON.stringify(this.data);
    return JSON.parse(stringData);
  }
  
  toJson() {
    return this.data;
  }
  
  toString() {
    return JSON.stringify(this.data, null, 2);
  }
}