
import RcDomainMap from "../../common/RcDomainMap.js";
import Role from './Role.js';

import ContentUtils      from '@/utils/ContentUtils.js';

export default class RoleMap extends RcDomainMap {
  static MODEL_NAME = Role.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, RoleMap.MODEL_NAME);
  }

  find() {
    var map = new RoleMap(this.domain);
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.domain.roles().findById(key);
      map.add(role);
    }
    return map;
  }
  
  first() {
    var keys = this.keys();
    var role = new Role(this.domain, {});
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      role = this.getRole(key);
      break;
    }
    return role;
  }
  
  getRole(key) {
    return new Role(this.domain, this.get(key));
  }
  
  isAdministrator() {
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.getRole(key);
      if (role.isAdministrator()) {
        return true;
      }
    }
    return false;
  }
 
  isDriver() {
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.getRole(key);
      if (role.isDriver()) {
        return true;
      }
    }
    return false;
  }
  
  isOwner() {
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.getRole(key);
      if (role.isOwner()) {
        return true;
      }
    }
    return false;
  }
  
  isManager() {
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.getRole(key);
      if (role.isManager()) {
        return true;
      }
    }
    return false;
  }
  
  display() {
    var roles = "";
    var keys = this.keys();
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var role = this.getRole(key);
      roles = roles + " " + ContentUtils.ROLES2()[role.name()];
    }
    return roles;
  }
  
  static map(role) {
    let roleMap = new RoleMap(this.domain);
    roleMap.add(role);
    return roleMap;
  }

  copy() {
    var data = this._copy();
    return new RoleMap(this.domain, data);
  }
}