
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import ContentUtils from "@/utils/ContentUtils.js";
import StringUtils from "@/utils/StringUtils.js";
import DateUtils from "@/utils/DateUtils.js";
import RcDate from '@/domain/session/time/RcDate.js';
import Signature from "./Signature.js";
import Employee from "@/domain/model/employee/Employee.js";

export default class User extends RcDomainObject {

  static MODEL_NAME = 'User';
  
  static LAST = 'last';
  
  static FIELDS = {
    FIRST: 'first',
    LAST: 'last',
    EMAIL: 'email',
    CITY: 'city',
    POSTAL: 'postal',
    PROVINCE: 'jurisdiction',
    COUNTRY: 'country',
    PHONE: 'phone',
    SIGNATURE: Signature.MODEL_NAME,
    LAST_SIGN_IN: "lastSignIn",
    CLIENT: "client",
    CLIENT_VERSION: "clientVersion",
    CLIENT_MAKE: "clientMake",
    ADDRESS: "address",
    STREET: "street",
    LICENSE_CLASS: "licenseClass",
    LICENSE_EXPIRY: "licenseExpiry",
    INVITE_NOW: "inviteNow",
  }
  
  constructor(domain, data) {
    super(domain, data, User.MODEL_NAME);
  }

  find() {
    return this.domain.users().findById(this.id());
  }
  
  fields() {
    return User.FIELDS;
  }
  
  lastSignIn() {
    return this.getInt(User.FIELDS.LAST_SIGN_IN);
  }
  
  lastSignInV2() {
    const value = this.lastSignIn();
    return this.domain.time().at(value);
  }
  clientName() {
    return this.getString(User.FIELDS.CLIENT);
  }

  clientVersion() {
    return this.getString(User.FIELDS.CLIENT_VERSION);
  }

  clientMake() {
    return this.getString(User.FIELDS.CLIENT_MAKE);
  }

  lastSignInDisplay() {
    var value = this.getString(User.FIELDS.LAST_SIGN_IN);
    return DateUtils.toDateTime(value);
  }
  
  first() {
    if (this.isSystem()) {
      return "ReadyChek";
    }

    return this.getString(User.FIELDS.FIRST);
  }

  last() {
    if (this.isSystem()) {
      return "System";
    }

    return this.getString(User.FIELDS.LAST);
  }

  city() {
    return this.getString(User.FIELDS.CITY);
  }
  
  postal() {
    return this.getString(User.FIELDS.POSTAL);
  }
  
  phone() {
    return this.getString(User.FIELDS.PHONE);
  }
  
  street() {
    return this.address();
  }
  
  address() {
    return this.getString(User.FIELDS.STREET);
  }
  
  signature() {
    return new Signature(this.domain, this.get(User.FIELDS.SIGNATURE));
  }
  
  email() {
    return this.getString(User.FIELDS.EMAIL);
  }

  emailDisplay() {
    let email = this.email();
    if (StringUtils.startsWith(email, "readychek2019+")) {
      return "";
    }
    return email;
  }
  
  jurisdiction() {
    return this.getString(User.FIELDS.PROVINCE);
  }
  
  country() {
    return this.getString(User.FIELDS.COUNTRY);
  }
  
  
  firstDisplay() {
    return ContentUtils.unescape(this.first());
  }

  lastDisplay() {
    return ContentUtils.unescape(this.last());
  }
  
  emailDisplayEscaped() {
    return ContentUtils.unescapeEmail(this.email());
  }
 
  licenseExpiry() {
    return this.getInt(User.FIELDS.LICENSE_EXPIRY);
  }
  
  licenseExpiryAsString() {
    return this.getString(User.FIELDS.LICENSE_EXPIRY);
  }

  licenseExpiryDate() {
    var time = this.licenseExpiry();
    var timeZone = this.timeZone();
    var date = new Date(time);
    var rcDate = new RcDate(date, timeZone);
    return rcDate;
  }
  
  licenseExpiryDateDisplay() {
    var rcDate = this.licenseExpiryDate();
    return rcDate.displayAs_YYYY_MM_DD();
  }

  licenseClass() {
    return this.getString(User.FIELDS.LICENSE_CLASS);
  }
  
  fullName() {
    return StringUtils.format("{0} {1}", [this.firstDisplay(), this.lastDisplay()])
  }
  
  modifiedBy() {
    return new User(this.domain, this.get(User.DefaultFields.MODIFIED_BY));
  }
  
  createdBy() {
    return new User(this.domain, this.get(User.DefaultFields.CREATED_BY));
  }
  
  isLicenseClassSet() {
    return !this.isLicenseClassMissing();
  }
  
  isLicenseClassMissing() {
    var licenseClass = this.licenseClass();
    return StringUtils.isEmpty(licenseClass);
  }
  
  isLicenseExpiryMissing() {
    var dateValue = this.licenseExpiry();
    if (dateValue <= 0) {
      return true;
    }
    return false;
  }
  
  hasLicenseExpired() {
    var dateValue = this.licenseExpiry();
    if (dateValue <= 0) {
      return false;
    }

    var rcDate = this.licenseExpiryDate();
    var now = this.domain.session().time().now();
    return rcDate.time() < now.time();
  }
  
  willLicenseExpireInWeeks(weeks) {
    var dateValue = this.licenseExpiry();
    if (dateValue <= 0) {
      return false;
    }

    var rcDate = this.licenseExpiryDate();
    var day = rcDate.day();
    var weeksAgo = day.weeksAgo(weeks);
    var nextYear = weeksAgo.year();
    var now = this.domain.session().time().now();
    
    var result = nextYear.time() <= now.time();
    return result;
  }
  
  licenseDaysLeft() {
    var dateValue = this.licenseExpiry();
    if (dateValue <= 0) {
      return -1;
    }

    var rcDate = this.licenseExpiryDate();
    var lastDay = rcDate.day();
    var nextYear = lastDay.year();
    var now = this.domain.session().time().now();
    var nowDay = now.day();
    var ms = nextYear.time() - nowDay.time();
    var daysLeft = Math.round(DateUtils.daysFromMs(ms));
    if (daysLeft < 0) {
      daysLeft = 0;
    }
    return daysLeft;
  }

  employee() {
    return new Employee(this.domain, this.get("employee"));
  }

  employees() {
    return this.domain.employees().findByUser(this);
  }

  rolesDisplay() {
    var employee = this.employee();
    if (employee.isPointer()) {
      return "";
    }
    return employee.roles().find().display();
  }
}