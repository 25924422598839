
import RcDomainObject from "../../common/RcDomainObject";
import CheckListMap from "../checklist/CheckListMap.js";
import Schedule from "../schedule/Schedule.js";
import Category from "../category/Category.js";
import Company from "../company/Company.js";
import ImageMap from "../image/ImageMap.js";
import Image from "../image/Image.js";
import StringUtils from "@/utils/StringUtils.js";
//import ContentUtils from "@/utils/ContentUtils.js";
import DateUtils from "@/utils/DateUtils.js";
import RcDate from "@/domain/session/time/RcDate.js";

export default class Vehicle extends RcDomainObject {
  
  static MODEL_NAME = "Vehicle";
      
  static FIELDS = {
    NAME : 'name',
    _CATEGORY : '_category',
    CATEGORY: Category.MODEL_NAME,
    COMPANY: Company.MODEL_NAME,
    JURISDICTION: 'jurisdiction',
    SCHEDULE: 'VehicleSchedule',
    PLATE: 'plate',
    DEFECT_TYPE: 'defectType',
    BRAKE_TYPE: 'brakingType',
    ODOMETER: 'odometer',
    ODOMETER_TYPE: "odometerType",
    SUMMARY: 'summary',
    VIN: 'vin',
    MAKE: 'make',
    MODEL: 'model',
    ANNUAL_DATE: 'cvorDate',
    ANNUAL_NUMBER: 'cvorNumber',
    YEAR: 'year',
    COLOUR: 'colour',
    WEIGHT: 'weight',
    IMAGE_DATA: "imageData",
    Checklists: CheckListMap.MODEL_NAME,
    CHECKLIST_MAP: CheckListMap.MODEL_NAME,
    Images: ImageMap.MODEL_NAME,
    IMAGE_URL: "image",

    PLATE_EXPIRY_DATE: 'plateExpiryDate',
    END_DATE: "end",
    START_DATE: "start",
    NOTES: "notes",
  }
  
  static Values = {
    Odometer: {
      Type: {
        KM: 'KM',
        MI: 'MI',
      }
    }
  }
  
  constructor(domain, data) {
    super(domain, data, Vehicle.MODEL_NAME);
  }

  fields() {
    return Vehicle.FIELDS;
  }
  
  find() {
    return this.domain.vehicles().findById(this.id());
  }

  weight() {
    return this.getString(Vehicle.FIELDS.WEIGHT);
  }
  
  hasWeightSet() {
    return this.hasWeight();
  }

  hasWeight() {
    var weight = this.getInt(Vehicle.FIELDS.WEIGHT);
    if (weight > 0) {
      return true;
    }
    return false;
  }
  
  imageUrl() {
    return this.getString(Vehicle.FIELDS.IMAGE_URL);
  }

  hasImageUrl() {
    return !StringUtils.isEmpty(this.imageUrl());
  }

  images() {
    var value = this.get(Vehicle.FIELDS.Images);
    var images = new ImageMap(this.domain, value);
    if (this.hasImageUrl() && images.isEmpty()) {
      var image = new Image(this.domain);
      image.withUrl(this.imageUrl());
      images.add(image);
    }
    return images;
  }
  
  withImages(imageMap) {
    this.put(Vehicle.FIELDS.Images, imageMap);
    return this;
  }
  
  withImageData(data) {
    this.put(Vehicle.FIELDS.IMAGE_DATA, data);
    return this;
  }
  
  notes() {
    return this.getString(Vehicle.FIELDS.NOTES);
  }

  plate() {
    return this.getString(Vehicle.FIELDS.PLATE);
  }
  
  isPmviValid() {
    return !StringUtils.isEmpty(this.annualDate());
  }
  
  withStartDate(date) {
    this.put(Vehicle.FIELDS.START_DATE, date);
    return this;
  }

  startDate() {
    return this.getString(Vehicle.FIELDS.START_DATE);
  }

  withEndDate(date) {
    this.put(Vehicle.FIELDS.END_DATE, date);
    return this;
  }

  endDate() {
    return this.getString(Vehicle.FIELDS.END_DATE);
  }

  isPlateExpiryDateValid() {
    return !StringUtils.isEmpty(this.plateExpiryDate());
  }

  plateExpiryDate() {
    return this.getString(Vehicle.FIELDS.PLATE_EXPIRY_DATE);
  }

  withPlateExpiryDate(date) {
    this.put(Vehicle.FIELDS.PLATE_EXPIRY_DATE, date);
    return this;
  }

  plateExpiryDateV2() {
    var dateValue = this.plateExpiryDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(parseInt(dateValue)), timeZone);
    return rcDate;
  }

  hasPlateExpired() {
    if (!this.isPlateExpiryDateValid()) {
      return false;
    }
    var expiryDate = this.plateExpiryDateV2();
    var now = this.domain.time().now();
    return expiryDate.isLt(now);
  }

  willPlateExpireInWeeks(weeks) {
    if (!this.isPlateExpiryDateValid()) {
      return false;
    }

    var rcDate = this.plateExpiryDateV2();
    var day = rcDate.day();
    var weeksAgo = day.weeksAgo(weeks);
    var nextYear = weeksAgo.year();
    var now = this.domain.session().time().now();
    
    var result = nextYear.time() <= now.time();
    return result;
  }
  
  daysLeftBeforePlateExpires() {
    if (!this.isPlateExpiryDateValid()) {
      return -1;
    }

    var rcDate = this.plateExpiryDateV2();
    var lastDay = rcDate.day();
    var nextYear = lastDay.year();
    var now = this.domain.session().time().now();
    var nowDay = now.day();
    var ms = nextYear.time() - nowDay.time();
    var daysLeft = Math.round(DateUtils.daysFromMs(ms));
    if (daysLeft < 0) {
      daysLeft = 0;
    }
    return daysLeft;
  }

  plateExpiryDateDisplay() {
    if (!this.isPlateExpiryDateValid()) {
      return "";
    }
  
    var rcDate = this.plateExpiryDateV2();
    return rcDate.displayAs_YYYY_MM_DD();
  }

  withAnnualDate(date) {
    this.put(Vehicle.FIELDS.ANNUAL_DATE, date);
    return this;
  }
  
  annualDateV2() {
    var dateValue = this.annualDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(parseInt(dateValue)), timeZone);
    return rcDate;
  }
  
  annualDate() {
    return this.getString(Vehicle.FIELDS.ANNUAL_DATE);
  }

  isLastSafetyDateValid() {
    return !StringUtils.isEmpty(this.annualDate());
  }

  annualDateDisplay() {
    if (!this.isPmviValid()) {
      return "";
    }
  
    var rcDate = this.annualDateV2();
    return rcDate.displayAs_YYYY_MM_DD();
  }
  
  plateExpiryDateDaysLeft() {
    var dateValue = this.plateExpiryDate();
    if (StringUtils.isEmpty(dateValue)) {
      return "";
    }
    var rcDate = this.plateExpiryDateV2();
    var now = this.domain.session().time().now();
    var nowDay = now.day();
    var ms = rcDate.time() - nowDay.time();
    var daysRemaining = DateUtils.daysFromMs(ms);
    var daysLeft = Math.floor(daysRemaining);
    if (daysLeft < 0) {
      daysLeft = 0;
    }
    return daysLeft;
  }

  hasLastSafetyDateExpired() {
    return this.hasAnnualDateExpired();
  }

  hasAnnualDateExpired() {
    var dateValue = this.annualDate();
    if (StringUtils.isEmpty(dateValue)) {
      return false;
    }
    var daysRemaining = this.annualDateDaysLeft();
    var expired = daysRemaining <= 0;
    return expired;
  }
  
  willLastSafetyDateExpireInWeeks(weeks) {
    return this.willAnnualDateExpireInWeeks(weeks);
  }
  
  willAnnualDateExpireInWeeks(weeks) {
    var dateValue = this.annualDate();
    if (StringUtils.isEmpty(dateValue)) {
      return false;
    }
    var rcDate = this.annualDateV2();
    var rcMonth = rcDate.month();
    var lastDay = rcMonth.lastDay();
    var lastHour = lastDay.lastHour();
    var day = lastHour.day();
    var weeksAgo2 = day.weeksAgo(weeks);
    var nextYear = weeksAgo2.year().next();
    if (this.isBus()) {
      nextYear = lastDay.month().firstDay().month().plus(6).lastDay().year()
    }
    var now = this.domain.session().time().now();
    return nextYear.time() <= now.time();
  }
  
  annualDateDaysLeft() {
    var dateValue = this.annualDate();
    if (StringUtils.isEmpty(dateValue)) {
      return "";
    }
    var rcDate = this.annualDateV2();
    var rcMonth = rcDate.month();
    var lastDay = rcMonth.lastDay().lastHour().day();
    var nextYear = lastDay.year().next().day().year();
    if (this.isBus()) {
      nextYear = lastDay.month().firstDay().month().plus(6).lastDay().year()
    }
    var now = this.domain.session().time().now();
    var nowDay = now.day();
    var ms = nextYear.time() - nowDay.time();
    var daysRemaining = DateUtils.daysFromMs(ms);
    var daysLeft = Math.floor(daysRemaining);
    if (daysLeft < 0) {
      daysLeft = 0;
    }
    return daysLeft;
  }
  
  safetyNumber() {
    return this.annualNumber();
  }
  
  annualNumber() {
    return this.getString(Vehicle.FIELDS.ANNUAL_NUMBER);
  }
  
  hasOdometerSet() {
    var value = this.odometer();
    return StringUtils.isNotEmpty(value);
  }

  odometer() {
    return this.getString(Vehicle.FIELDS.ODOMETER);
  }

  odometerType() {
    var value = this.getString(Vehicle.FIELDS.ODOMETER_TYPE);
    return value;
  }

  defectType() {
    return this.getString(Vehicle.FIELDS.DEFECT_TYPE);
  }
 
  defectTypeDisplay() {
    var defectType = this.defectType();
    var display = "Good";
    if (defectType === "Major") {
      display = "Major";
    }
    if (defectType === "Minor") {
      display = "Minor";
    }
    return display
  }
 
  isDefectTypeGood() {
    var defectType = this.defectType();
    if (StringUtils.isEmpty(defectType) || defectType == "None") {
      return true;
    }
    return false;
  }
  
  isDefectTypeMinor() {
    var defectType = this.defectType();
    if (defectType == "Minor") {
      return true;
    }
    return false;
  }
  
  isDefectTypeMajor() {
    var defectType = this.defectType();
    if (defectType == "Major") {
      return true;
    }
    return false;
  }
  
  brakeType() {
    return this.getString(Vehicle.FIELDS.BRAKE_TYPE);
  }
  
  brakeTypeDisplay() {
    var type = this.brakeType();
    if (type === "abs") {
      return "Air Brakes";
    }
    if (type === "ebs") {
      return "Electric Brakes";
    }
    if (type === "hbs") {
      return "Hydraulic Brakes";
    }
    return "None";
  }
  
  vin() {
    return this.getString(Vehicle.FIELDS.VIN);
  }
  
  category() {
    var value = this.get(Vehicle.FIELDS.CATEGORY);
    return new Category(this.domain, value);
  }

  withCompany(company) {
    this.put(Vehicle.FIELDS.COMPANY, company);
    return this;
  }
  
  company() {
    var value = this.get(Vehicle.FIELDS.COMPANY);
    return new Company(this.domain, value);
  }

  
  withCategory(category) {
    this.put(Vehicle.FIELDS.CATEGORY, category);
    return this;
  }
  
  with_Category(category) {
    this.put(Vehicle.FIELDS._CATEGORY, category);
    return this;
  }

  _category() {
    var value = this.getString(Vehicle.FIELDS._CATEGORY);
    return value;
  }
  
  year() {
    var value = this.getString(Vehicle.FIELDS.YEAR);
    return value;
  }
  
  checklists() {
    var value = this.get(Vehicle.FIELDS.CHECKLIST_MAP);
    return new CheckListMap(this.domain, value);
  }
  
  schedule() {
    var value = this.get(Vehicle.FIELDS.SCHEDULE);
    return new Schedule(this.domain, value);
  }
  
  withSchedule(schedule) {
    this.put(Vehicle.FIELDS.SCHEDULE, schedule);
    return this;
  }
  
  withChecklist(checklist) {
    this.put(Vehicle.FIELDS.CHECKLIST_MAP, checklist.map());
    return this;
  }
  
  withChecklists(checklists) {
    this.put(Vehicle.FIELDS.CHECKLIST_MAP, checklists);
    return this;
  }
  
  summary() {
    return this.getString(Vehicle.FIELDS.SUMMARY);
  }
  
  colour() {
    return this.getString(Vehicle.FIELDS.COLOUR);
  }

  make() {
    return this.getString(Vehicle.FIELDS.MAKE);
  }
  
  model() {
    return this.getString(Vehicle.FIELDS.MODEL);
  }
  
  makeModelDisplay() {
    var name = "{0} {1}";
    var make = this.make();
    var model = this.model();
    var value = StringUtils.format(name, [make, model]);
    return StringUtils.trim(value);
  }
  
  isTruck() {
    return this.isVehicle();
  }
  
  isTrailer() {
    var category = this.category(); 
    return category.isTrailer();
  }
  
  isBus() {
    var category = this.category();
    return category.isBus();
  }
  
  isVehicle() {
    return !this.isEquipment() && !this.isTrailer();
  }

  isEquipment() {
    var category = this.category(); 
    return category.isEquipment();
  }

  name() {
    return this.getString(Vehicle.FIELDS.NAME);
  }
  
  jurisdiction() {
    return this.getString(Vehicle.FIELDS.JURISDICTION)
  }
  
  copy() {
    var data = this._copy();
    return new Vehicle(this.domain, data);
  }
}