export default class ConstUtils {
  static FIELDS = {
    ID: '@rid',
    COMPANY: 'Company',
    TYPE: 'VehicleType',
    SCHEDULE: 'VehicleSchedule',
    VEHICLE: 'Vehicle',
    CVORNUMBER: 'cvorNumber',
    CVORDATE: 'cvorDate',
    CLASS: '@class',
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_DATE: 'modifiedDate',
  }

  static DEFAULT = {
    MODIFIED_BY: 'modifiedBy',
    MODIFIED_DATE: 'modifiedDate',
    CREATED_BY: 'createdBy',
    CREATED_DATE: 'createdDate',
  }
  
  static REGEXP = {
    GLOBAL_REGEXP: /.*/,
    USER_STATE: {
      ACTIVE_REGEXP: /\bactive\b/i,
      INACTIVE_REGEXP: /\binactive\b/i,
      INVITED_REGEXP: /\binvited\b/i,
    },
    DEFECT_TYPE: {
      GOOD_REGEXP: /\bnone\b/i,
      MINOR_REGEXP: /\bminor\b/i,
      MAJOR_REGEXP: /\bmajor\b/i,
    },
    VEHICLE_STATE: {
      ACTIVE_REGEXP: /\bfalse\b/i,
      INACTIVE_REGEXP: /\btrue\b/i,
    },
    VEHICLE_TYPE: {
      ALL_REGEXP: 'all',
      TRAILER_REGEXP: /\btrailer\b/i,
      VEHICLE_REGEXP: /\bnottrailer\b/i,
    }
  }

  static NULLABLE = [
       this.FIELDS.CVORDATE, 
       this.FIELDS.CVORNUMBER
       ]

  static VALUES = {
    NO_IMAGE: '/companys/no_image_available.png',
    NAN: 'NaN',
  }

  static STORES = {
    AUTH_USER: 'auth_user',
  }

  static QUESTIONS = {
    VALUES: {
      MINOR: 'minor',
      MAJOR: 'major',
      NA: 'NA',
      NA_ID: "#19:1",
      OK: 'OK',
      OK_ID: "#19:0",
      OTHER: "other",
      REMARK: "other"
    },
  }

  static RESULTS = {
    VALUES: {
      VEHICLE_INITIAL: 'vehicleInitial',
      VEHICLE_ENROUTE: 'vehicleEnroute',
      VEHICLE: 'vehicle',
      TRAILER: 'trailer',
      TRAILER_INITIAL: 'trailerInitial',
      TRAILER_ENROUTE: 'trailerEnroute',
    },
  }

  static OPTIONS = {
    FIELDS: {
      VALUE: 'value',
    },
}

  static FEATURE = {
    VALUES: {
      EMPLOYEEID: 'com.readychek.feature.employmentid',
    },
  }

  static USER = {
    FIELDS: {
      FIRST: 'first',
      LAST: 'last',
      EMAIL: 'email',
    },
  }

  static EMPLOYEE = {
    FIELDS: {
      EMPLOYMENT_ID: 'employmentId',
    },
    VALUES: {
      STATE: {
        ACTIVE: 'active',
        INACTIVE: 'inactive',
        DELETED: 'deleted',
      },
    }
  }

  static VEHICLE = {
    FIELDS: {
      IMAGEDATA: 'imageData',
    },
    VALUES: {
      DELETED_FALSE: 'false',
    },
  }

  static CATEGORY = {
    FIELDS: {},
    VALUES: {
      TRAILER: 'trailer',
      VEHICLE: 'vehicle',
    },
  }
  
  static ROUTES = {
    SIGNIN: 'signin',
    LOST_PASSWORD: 'recovery',
    HOME: 'operatorHome',
    VEHICLES: 'portalvehicles',
    VEHICLE_ADD: 'portalvehicleadd',
    
    VEHICLE_EDIT: 'portalvehicleedit',
    VEHICLE_DETAILS: 'portalvehicledetails',
    VEHICLE: {
      LIST: 'portalvehicles',
      DETAILS: 'portalvehicledetails',
      ADD: 'portalvehicleadd',
      ADD_V2: 'portalvehicleaddv2',
      ADD_PATH: "/portal/operator/{0}/vehicles/add",
      EDIT: 'portalvehicleedit',
      EDIT_V2: 'portalvehicleeditv2',
      INSPECTIONS: 'portalVehicleInspections'
    },
    
    SCHEDULE: {
      LIST: 'portalOperatorSchedules',
      DETAILS: 'portalOperatorSchedulesDetails',
      EDIT: 'portalOperatorSchedulesEdit',
      ADMIN: {
        LIST: 'portalAdminCompanySchedules',
      }
    },
    
    
    STATS: {
      ADMIN: {
        HOME: 'portalAdminCompanyStats',
      },
    },
    
    COMPANY_ADMIN_DETAILS: 'portalAdminCompanyDetails',
    COMPANY_NEW: 'adminCompanyNew',

    COMPANY: {
      SELECT: 'portalOperatorCompanySelect',
      ADMIN: {
        DETAILS: 'portalAdminCompanyDetails',
      },
      NEW: 'adminCompanyNew',
      LIST: 'portalAdminCompanys',
    },
    
    INSPECTION_DETAILS: 'portalinspectiondetails',
    INSPECTION_REPAIRS: 'portalinspectionrepairs',
    INSPECTIONS: 'operatorInspectionsList',
    INSPECTION: {
      LIST: 'operatorInspectionsList',
      LIST_PATH: '/portal/operator/{0}/inspections',
      DETAILS: 'portalinspectiondetails',
      REPAIR: 'portalinspectionrepairs',
      EMAIL: 'portalInspectionEmail',
      REMARKS: 'portalInspectionRemarks',
    },
    
    EMPLOYEE_INSPECTIONS: 'portalinspectionsemployee',
    EMPLOYEES: 'portalOperatorUsers',
    
    EMPLOYEE: {
      LIST: 'portalOperatorUsers',
      LIST_PATH: '/portal/operator/{0}/users',
      LIST_PATH_ADMIN: '/portal/admin/companys/{0}/employees',
      DETAILS: 'portalOperatorUsersDetails',
      EDIT: 'portalOperatorUsersEdit',
      EDIT_PATH: '/portal/operator/{0}/users/{1}/edit',
      LICENSE: 'portalOperatorUsersLicense',
      HOURS: 'portalOperatorUsersHours',
      INSPECTIONS: 'portalOperatorUsersInspections',
      FILE: 'portalOperatorUsersFile',
      INVITE: 'portalOperatorUsersInvite',
      INVITE_PATH: '/portal/operator/{0}/users/invite',
      ADD: 'portalOperatorUsersAdd',
      ADD_PATH: '/portal/operator/{0}/users/add',
    },
    
    EMPLOYEE_INVITE: 'portalemployeesinvite',
    EMPLOYEE_ADD: 'portalemployeesadd',
    EMPLOYEE_REGISTER: 'portalemployeesregister',
    EMPLOYEE_REMOVE: 'portalemployeesremove',
    EMPLOYEE_EDIT: 'portalemployeesedit',
    
    CHECKINS: 'operatorCheckIns',
    CHECKIN: {
      LIST: 'operatorCheckIns',
      DETAIL: 'operatorCheckInDetails',
    },
    
    CHANGE_PASSWORD: 'portalchangepassword',
    PROFILE: 'portalprofile',
    SETTINGS: 'portalsettings',
    
    SIGNUP: 'register',
    
    ADMIN_HOME: 'portaladminhome',
    ADMIN_REGISTER: 'portaladminregister',
    ADMIN_COMPANYS: 'portalAdminCompanys',
    
    TIMESHEET: {
      DETAILS: 'operatorTimesheetDetails',
      LIST: 'operatorTimesheetList',
      LIST_PATH: '/portal/operator/{0}/hours',
    },
    
    PAYMENT: {
      CARD_EDIT: 'operatorPaymentEdit', 
    },
    
    OPERATOR: {
      BILLING_EDIT: 'operatorBillingDisplay',
    },
    
    COMPLIANCE: {
      USERS: 'operatorCompliance',
    },
    
    INVOICE: {
      PAYMENT_LINK: 'operatorBillingPaymentLink',
    },
    CHECKLIST: {
      LIST: 'portalOperatorCheckListLists',
      EDIT: 'portalOperatorCheckListEdit',
      DETAILS: 'portalOperatorCheckListDetails',
      ADD: 'portalOperatorCheckListAdd',
    },
  }

  static LABELS = {
    READYCHECK: 'readychek',
    IMAGE_NOT_FOUND: '/companys/no_image_available.png',
    PRETRIP: 'Pre-Trip',
    POSTTRIP: 'Post-Trip',
    DECLARATION_PREFIX: 'I declare that this vehicle has been inspected in accordance with',
  }

  static COMPANY = {
      FIELDS: {
        COUNTRY: "country"
      }
  }
  
  static PATHS = {
    VEHICLE: 'vehicle',
  }

  static GRECAPTCHA = {
    NAME: 'grecaptcha',
    ID: '6Lfe0dQUAAAAABmOEK2DzrmfdKKHOr4h2iFABrWl',
    PAGES: {
      SIGNIN: 'signIn',
      REGISTER: 'register',
    },
  }
}
